export function isInRange({
  value,
  min,
  max,
}: {
  value: number;
  min: number;
  max: number;
}): boolean {
  // Case 1: Both min and max are 0
  if (min === 0 && max === 0) {
    return true;
  }

  // Case 2: Only min is 0
  if (min === 0 && max !== 0) {
    return value <= max;
  }

  // Case 3: Only max is 0
  if (min !== 0 && max === 0) {
    return value >= min;
  }

  // Case 4: Neither min nor max is 0
  if (min !== 0 && max !== 0) {
    return value <= max && value >= min;
  }

  // Default case (should not be reached)
  return false;
}

export function IsQueryFilter({
  value,
  filter,
}: {
  value: string;
  filter: string;
}): boolean {
  // Case 1: Filter is empty
  if (filter.length === 0) {
    return true;
  }

  // Case 2: Filter is not empty
  return value.toUpperCase().includes(filter.toUpperCase());
}

export function parsePercentageString(percentageString: string) {
  // Удалить знак процента
  const cleanedString = percentageString.replace("%", "");
  // Заменить запятую на точку
  const normalizedString = cleanedString.replace(",", ".");
  // Преобразовать строку в число
  return parseFloat(normalizedString);
}

export function makeLinkFromAsin(asin: string) {
  if (!asin) return "None";
  return `https://www.amazon.com/dp/${asin}`;
}
