import { Input } from "@/components/ui/input.tsx";
import {
  ChangeEvent,
  PropsWithChildren,
  useEffect,
  useMemo,
  useState,
} from "react";
import { OptionFilterReturn } from "@/pages/TrendsPage/TrendsPage.tsx";
import { ClientFilters } from "@/hooks/usePaginatedTrends/usePaginatedTrends.tsx";
import { Label } from "@/components/ui/label.tsx";
import { ColumnSettingsByColumnNameInOrder } from "@/hooks/ColumnSettingsByColumnName/ColumnSettingsByColumnNameInOrder.tsx";
import { debounce } from "lodash";
import { useMe } from "@/hooks/useMe.tsx";

export const QueryFilter = ({
  onSearchQuery,
  queryKey,
  children,
  dropFilter,
  selectedSavedSearch,
}: {
  onSearchQuery: ({ filters }: { filters: OptionFilterReturn[] }) => void;
  queryKey: ClientFilters;
  dropFilter: boolean;
  selectedSavedSearch: any[];
} & PropsWithChildren) => {
  const [searchQuery, setSearchQuery] = useState("");

  const { me } = useMe({});
  const filterList = me?.filter_list || [];

  const filterText = queryKey
    ? // eslint-disable-next-line @typescript-eslint/ban-ts-comment
      // @ts-ignore
      ColumnSettingsByColumnNameInOrder[queryKey]?.shownName
    : "-";

  useEffect(() => {
    if (filterList.length !== 0) {
      const foundFilter = filterList?.find((f) => f.queryKey === queryKey);
      if (foundFilter !== undefined) {
        setSearchQuery(foundFilter.filterValue);
        onSearchQuery({
          filters: [{ queryKey, filterValue: foundFilter.filterValue }],
        });
      }
    }
  }, []);

  useEffect(() => {
    const foundFilter = selectedSavedSearch?.find(
      (f) => f?.queryKey === queryKey,
    );

    if (foundFilter !== undefined) {
      setSearchQuery(foundFilter.filterValue);
      onSearchQuery({
        filters: [{ queryKey, filterValue: foundFilter.filterValue }],
      });
    }
  }, [onSearchQuery, queryKey, selectedSavedSearch]);

  useEffect(() => {
    if (dropFilter === true) {
      setSearchQuery("");
      onSearchQuery({ filters: [{ queryKey, filterValue: "" }] });
    }
  }, [dropFilter]);

  const debouncedHandler = (val: any) => {
    if (onSearchQuery) {
      onSearchQuery({ filters: [{ queryKey, filterValue: val }] });
    }
  };

  const debouncedSearch = useMemo(() => debounce(debouncedHandler, 300), []);

  const onChange = (e: ChangeEvent<HTMLInputElement>) => {
    const val = e.target.value;
    setSearchQuery(val);
    debouncedSearch(val);
  };

  return (
    <div className={"flex flex-col w-full"}>
      <Label
        className={` ${searchQuery && "!text-black"} text-sm font-light  text-gray-500 truncate `}
      >
        {children || filterText}
      </Label>
      <Input
        value={searchQuery}
        accentBorder
        className="h-8 border-0 border-b border-gray-400 focus:border-b-accent-1 rounded-none !shadow-none"
        onChange={onChange}
      />
    </div>
  );
};
