import { create } from "zustand";
import { createJSONStorage, persist } from "zustand/middleware";

interface SurveyState {
  formPage: number;
  formPageIsOpen: boolean;
  setFormPage: (page: number) => void;
  setFormPageIsOpen: (isOpen: boolean) => void;
}

const useSurveyStore = create<SurveyState>()(
  persist(
    (set) => ({
      formPage: 0,
      formPageIsOpen: false,
      setFormPage: (page: number) => set({ formPage: page }),
      setFormPageIsOpen: (isOpen: boolean) => set({ formPageIsOpen: isOpen }),
    }),
    {
      name: "survey-store",
      storage: createJSONStorage(() => localStorage),
    },
  ),
);

export default useSurveyStore;
