export const roles = [
  {
    role: "Seller",
    description: "I am currently selling or planning to sell on Amazon",
  },
  {
    role: "Virtual Assistant",
    description: "I work as an assistant, I help on Amazon",
  },
  {
    role: "Agency",
    description:
      "I work with multiple sellers who sell on Amazon within an agency",
  },
  {
    role: "Other",
    description: "",
  },
];

export const business = [
  "Online Arbitrage",
  "Dropshipping",
  "Wholesale",
  "Retail Arbitrage",
  "Private Label",
  "Other",
];

export const experience = [
  "I'm just getting started",
  "Less than a year",
  "Year or more",
  "Other",
];

export const sources = [
  "Google",
  "Facebook",
  "Instagram",
  "Courses",
  "YouTube",
  "Friend's advice",
  "Other",
];
