import { Label } from "@/components/ui/label.tsx";
import { FormInput } from "@/components/form/FormInput.tsx";
import { PASSWORD_PATTERNS } from "@/consts/consts.ts";
import { Button } from "@/components/ui/button";
import useResetPasswordForm from "@/pages/LoginPage/LoginForm/useResetPasswordForm.tsx";
import { useEffect } from "react";
import { toast } from "sonner";
import { useSearchParams } from "react-router-dom";

const ResetPasswordPage = () => {
  const { newPasswordForm, onNewPasswordSubmit } = useResetPasswordForm();
  const [searchParams] = useSearchParams();
  const token = searchParams.get("token");

  useEffect(() => {
    if (!token) {
      toast.error("Your reset link is broken.");
      navigation("/");
    }
  }, [token]);

  return (
    <div className="w-full h-screen bg-white flex items-center justify-center">
      <form
        onSubmit={onNewPasswordSubmit}
        className="flex flex-col w-[340px] gap-4"
      >
        <div className="flex flex-col space-y-1.5 w-full">
          <Label className="text-muted-foreground text-xs">New password</Label>
          <FormInput
            form={newPasswordForm}
            validation={{
              pattern: {
                message: "password validation",
                value: PASSWORD_PATTERNS,
              },
              required: "Password is required",
              minLength: { value: 6, message: "Short password" },
              validate: (value) => {
                return (
                  value === newPasswordForm.watch("confirmPassword") ||
                  "New passwords do not match"
                );
              },
            }}
            className="!h-10"
            fieldName="password"
            type="password"
            password
          />
        </div>
        <div className="flex flex-col space-y-1.5 w-full">
          <Label className="text-muted-foreground text-xs">
            Confirm new password
          </Label>
          <FormInput
            form={newPasswordForm}
            validation={{
              required: "Password is required",
              validate: (value) => {
                return (
                  value === newPasswordForm.watch("password") ||
                  "New passwords do not match"
                );
              },
            }}
            fieldName="confirmPassword"
            type="password"
            className="!h-10"
            password
          />
        </div>
        <Button type="submit" variant="accent" className="w-full md:w-40">
          Reset Password
        </Button>
      </form>
    </div>
  );
};

export default ResetPasswordPage;
