import React, { useMemo } from "react";
import { Link, useLocation } from "react-router-dom";
import { Button } from "@/components/ui/button";
import { LucideIcon } from "lucide-react";

interface NavButtonProps {
  to: string;
  icon: LucideIcon;
  children: React.ReactNode;
}

const NavButton: React.FC<NavButtonProps> = React.memo(
  ({ to, icon: Icon, children }) => {
    const location = useLocation();
    const isActive = location.pathname === to;

    const buttonClasses = useMemo(() => {
      return `flex flex-row gap-2 ${
        isActive
          ? "bg-gray-200 text-black font-bold pointer-events-none transition-colors"
          : ""
      }`;
    }, [isActive]);

    return (
      <Link to={to}>
        <Button variant="menu" size="sm" className={buttonClasses}>
          <Icon
            className={`w-4 h-4 ${isActive && "font-bold"}`}
            strokeWidth={isActive ? 2 : 1}
          />
          {children}
        </Button>
      </Link>
    );
  },
);

NavButton.displayName = "NavButton";

export default NavButton;
