import { useQueryClient } from "@tanstack/react-query";
import { useEffect } from "react";
import { useNavigate } from "react-router-dom";

export const SuccessPaymentPage = () => {
  const queryClient = useQueryClient();
  const navigation = useNavigate();

  useEffect(() => {
    const refetch = async () => {
      await queryClient.invalidateQueries({ queryKey: ["me"] });
      navigation("/trends");
    };

    setTimeout(() => {
      void refetch();
    }, 8000);
  }, []);

  return (
    <div className={"w-full h-full flex justify-center items-center"}>
      <div>Successful payment, you will be redirected</div>
    </div>
  );
};
