import { useRef } from "react";

export const useTrendsPageScroll = () => {
  const scrollAreaRef = useRef<any>(null);

  const scrollToTop = () => {
    if (scrollAreaRef.current) {
      scrollAreaRef.current.scrollTo({
        top: 0,
        behavior: "smooth",
      });
    }
  };

  return {
    scrollAreaRef,
    scrollToTop,
  };
};
