import { create } from "zustand";
import { persist, createJSONStorage } from "zustand/middleware";

interface AuthState {
  accessToken: string;
  refreshToken: string;
  setToken: ({
    accessToken,
    refreshToken,
  }: {
    accessToken: string;
    refreshToken: string;
  }) => void;
  clearToken: () => void;
}

const useAuthStore = create<AuthState>()(
  persist(
    (set) => ({
      accessToken: "",
      refreshToken: "",
      setToken: ({ accessToken, refreshToken }) =>
        set({ accessToken, refreshToken }),
      clearToken: () => set({ accessToken: "", refreshToken: "" }),
    }),
    {
      name: "auth-store", // name of the item in the storage (must be unique)
      storage: createJSONStorage(() => localStorage),
    },
  ),
);

export default useAuthStore;
