import { create } from "zustand";
import { ONBOARDING_CLASSES } from "@/components/onboarding/useOnboarding.tsx";

interface OnboardingStoreState {
  currentOnboardingStep: number;
  isReady: boolean;
  nextStep: () => void;
  prevStep: () => void;
  stop: boolean;
  setIsReady: (isReady: boolean) => void;
  setStop: (stop: boolean) => void;
  onboardingCanShow: boolean;
  startOnboarding: (onboardingCanShow?: boolean) => void;
  setCurrentOnboardingStep: (currentOnboardingStep: boolean) => void;
}

const baseState = {
  currentOnboardingStep: 0,
  isReady: false,
  stop: false,
  onboardingCanShow: false,
};

export const useOnboardingStore = create<OnboardingStoreState>()((set) => ({
  ...baseState,
  prevStep: () =>
    set((state: OnboardingStoreState) => ({
      isReady: false,
      currentOnboardingStep: state.currentOnboardingStep - 1,
    })),
  nextStep: () => {
    set((state: OnboardingStoreState) => {
      const nextStepNum = state.currentOnboardingStep + 1;
      if (nextStepNum > Object.keys(ONBOARDING_CLASSES).length - 1) {
        return {
          isReady: false,
          currentOnboardingStep: 0,
          onboardingCanShow: false,
        };
      }
      if (state.isReady) {
        return {
          isReady: false,
          currentOnboardingStep: nextStepNum,
        };
      }
      return state;
    });
  },
  setIsReady: (isReady = true) =>
    set(() => ({
      isReady,
    })),
  setStop: (stop = true) =>
    set(() => ({
      stop,
      currentOnboardingStep: 0,
      onboardingCanShow: false,
    })),
  setCurrentOnboardingStep: (currentOnboardingStep: number) => {
    set(() => ({
      currentOnboardingStep,
    }));
  },
  startOnboarding: (onboardingCanShow = true) =>
    set(() => ({
      onboardingCanShow,
    })),
}));
