import { keepPreviousData, useQuery } from "@tanstack/react-query";
import { TrendsPaginatedResponse } from "@/types/types.ts";
import { toast } from "sonner";
import AxiosInstance from "@/services/axios-instance.ts";

const transformCategories = (categoryData) => {
  return categoryData.map((item) => {
    const transformedItem = {
      ...item,
      value: item.id,
      label: item.category,
    };

    if (item.child && item.child.length > 0) {
      transformedItem.children = transformCategories(item.child);
    }

    return transformedItem;
  });
};

export const useCategoriesList = () => {
  const {
    isPlaceholderData,
    data: categories = [],
    refetch,
  } = useQuery({
    placeholderData: keepPreviousData,
    queryKey: [`categories-list`],
    queryFn: async ({ signal }) => {
      try {
        const url = `/category`;
        const resp = await AxiosInstance.get<TrendsPaginatedResponse>(url, {
          signal,
        });
        return transformCategories(resp?.data?.category);
      } catch (err: any) {
        if (err?.code === "ERR_CANCELED") return null;
        toast.error(err?.message);
        return null;
      }
    },
    staleTime: 0,
  });

  return {
    isLoading: isPlaceholderData,
    categories: categories || [],
    refetchCategories: refetch,
  };
};
