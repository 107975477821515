import { CheckIcon, CopyIcon } from "@radix-ui/react-icons";
import { useState } from "react";

interface CopyToClipboardProps {
  content: string;
}

const CopyToClipboard: React.FC<CopyToClipboardProps> = ({ content }) => {
  const [isCopied, setIsCopied] = useState(false);

  const handleCopy = async () => {
    try {
      await navigator.clipboard.writeText(content);
      setIsCopied(true);
      setTimeout(() => setIsCopied(false), 2000);
    } catch (err) {
      console.error("Failed to copy text: ", err);
    }
  };

  return (
    <button
      onClick={handleCopy}
      className="p-1 text-gray-500 hover:bg-grey-hover rounded transition-colors"
      title="Copy to clipboard"
    >
      {isCopied ? (
        <CheckIcon className="w-4 h-4 text-gray-400" />
      ) : (
        <CopyIcon className="w-4 h-4 text-gray-400" />
      )}
    </button>
  );
};

export default CopyToClipboard;
