import AxiosInstance from "@/services/axios-instance.ts";

export const usePayment = () => {
  const updatePayment = async () => {
    const url = `/update_payment`;
    try {
      const resp = await AxiosInstance.get(url);
      return resp.data.data.id;
    } catch (error) {
    } finally {
    }
  };

  return {
    updatePayment,
  };
};
