import { SparkAreaChart } from "@/components/charts/SparkAreaChart/SparkAreaChart.tsx";
import { formatDateToMM } from "@/lib/utils.ts";
import { AreaChart } from "@/components/charts/AreaChart/AreaChart.tsx";
import {
  Popover,
  PopoverContent,
  PopoverTrigger,
} from "@radix-ui/react-popover";

import { memo } from "react";
import {
  checkStepPositionByName,
  ONBOARDING_CLASSES,
} from "@/components/onboarding/useOnboarding.tsx";
import { useOnboardingStore } from "@/components/onboarding/useOboardingStore.tsx";

type Dates = {
  dates_monthly: Record<string, number>;
  dates_weekly: Record<string, number>;
};

function mergeDates(dates: Dates): Record<string, number> {
  const { dates_monthly, dates_weekly } = dates;

  // Функция для получения новой даты, сдвинутой на days дней
  const shiftDate = (dateStr: string, days: number) => {
    const date = new Date(dateStr);
    date.setDate(date.getDate() + days);
    return date.toISOString().split("T")[0]; // Форматируем как 'YYYY-MM-DD'
  };

  // Добавляем по две точки до и одну точку после для каждой даты из dates_monthly
  const extendedMonthlyDates: Record<string, number> = {};
  for (const [date, value] of Object.entries(dates_monthly)) {
    // Две точки до
    extendedMonthlyDates[shiftDate(date, -2)] = null;
    extendedMonthlyDates[shiftDate(date, -1)] = null;
    // Оригинальная дата
    extendedMonthlyDates[date] = value;
    // Точка после
    extendedMonthlyDates[shiftDate(date, 1)] = null;
  }

  // Находим первую дату из dates_weekly
  const firstWeeklyDate = Object.keys(dates_weekly).sort(
    (a, b) => new Date(a).getTime() - new Date(b).getTime(),
  )[0];

  // Фильтруем dates_monthly так, чтобы даты не выходили за пределы первой weekly даты
  const filteredMonthlyDates = Object.fromEntries(
    Object.entries(extendedMonthlyDates).filter(
      ([date]) =>
        new Date(date).getTime() < new Date(firstWeeklyDate).getTime(),
    ),
  );

  // Объединяем filteredMonthlyDates и dates_weekly
  const mergedDates: Record<string, number> = {
    ...filteredMonthlyDates,
    ...dates_weekly,
  };

  // Сортируем даты по ключам
  const sortedDates = Object.fromEntries(
    Object.entries(mergedDates).sort(
      ([dateA], [dateB]) =>
        new Date(dateA).getTime() - new Date(dateB).getTime(),
    ),
  );

  return sortedDates;
}

const ChartExpander = ({
  data,
  isOnboardingTrend = false,
}: {
  data: any[];
  isOnboardingTrend?: boolean;
}) => {
  const chartsWithFakeData = mergeDates(data);
  const shownData = Object.entries(chartsWithFakeData).map((e) => ({
    date: formatDateToMM(e[0]),
    "Search volume": e[1],
  }));

  // const processedData = shownData.map((d, index) => ({
  //   date: d.date,
  //   searchVolume: d?.["Search volume"],
  //   timeslot: index + 1,
  // }));
  //
  // const weights = processedData.map((data) => data.searchVolume);
  // const yMax = Math.max(...weights);
  // const yMin = Math.min(...weights);
  // const timestamps = processedData.map((data) => data.timeslot);
  // const xMax = Math.max(...timestamps);
  // const xMin = Math.min(...timestamps);
  //
  // const linearRegression = (x, y) => {
  //   const n = x.length;
  //   const sumX = x.reduce((a, b) => a + b, 0);
  //   const sumY = y.reduce((a, b) => a + b, 0);
  //   const sumXY = x.reduce((sum, xi, i) => sum + xi * y[i], 0);
  //   const sumX2 = x.reduce((sum, xi) => sum + xi * xi, 0);
  //
  //   const m = (n * sumXY - sumX * sumY) / (n * sumX2 - sumX * sumX);
  //   const b = (sumY - m * sumX) / n;
  //
  //   return { m, b };
  // };
  //
  // const { m, b } = linearRegression(timestamps, weights);
  //
  // const trendData = () => {
  //   return [
  //     { searchVolume: m * xMin + b, timeslot: xMin },
  //     { searchVolume: m * xMax + b, timeslot: xMax },
  //   ];
  // };

  const { nextStep, currentOnboardingStep } = useOnboardingStore();
  const open =
    (isOnboardingTrend &&
      [
        checkStepPositionByName(ONBOARDING_CLASSES.searchHistoryStepClick),
        checkStepPositionByName(ONBOARDING_CLASSES.searchHistorySeasons),
      ].includes(currentOnboardingStep)) ||
    undefined;

  return (
    <Popover open={open}>
      <PopoverTrigger asChild>
        <div
          className={`cursor-pointer -z-1 ${isOnboardingTrend && ONBOARDING_CLASSES.searchHistoryStep}`}
          onClick={() => {
            isOnboardingTrend && nextStep();
          }}
        >
          <SparkAreaChart
            connectNulls
            categories={["Search volume"]}
            index={"month"}
            colors={["orange"]}
            className="h-8 w-20 sm:h-10 sm:w-36 z-0"
            data={shownData}
          />
        </div>
      </PopoverTrigger>
      <PopoverContent
        className={`z-40`}
        avoidCollisions
        side={"bottom"}
        sticky="always"
      >
        <div className={"rounded-xl !cursor-pointer"}>
          {/*<div className={"absolute   max-h-[550px]"}>*/}
          {/*  <LineChart width={750} height={330} data={processedData}>*/}
          {/*    <CartesianGrid strokeDasharray="3 3" />*/}
          {/*    <Tooltip />*/}

          {/*    <Line*/}
          {/*      type="monotone"*/}
          {/*      dataKey="searchVolume"*/}
          {/*      data={trendData()}*/}
          {/*      stroke="red"*/}
          {/*      dot={false}*/}
          {/*      strokeDasharray="5 5"*/}
          {/*    />*/}
          {/*  </LineChart>*/}
          {/*</div>*/}

          <AreaChart
            isOnboardingTrend={isOnboardingTrend}
            colors={"orange"}
            yAxisLabel={""}
            className="p-1 bg-white max-h-[550px] min-w-[750px] border border-gray-200 shadow-2xl rounded-xl"
            data={shownData}
            index="date"
            legendPosition={"center"}
            categories={["Search volume"]}

            // valueFormatter={(number: number) => {
            //   console.log(number);
            //   // return `$${Intl.NumberFormat("us").format(number).toString()}`;
            //   return String(number);
            // }}
            // onValueChange={(v) => console.log(v)}
          />
        </div>
      </PopoverContent>
    </Popover>
  );
};

export default memo(ChartExpander);
