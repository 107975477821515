import { ChevronsLeftIcon, ChevronsRightIcon, Menu } from "lucide-react";
import { Button } from "@/components/ui/button.tsx";
import { useState } from "react";

interface SideMenuButtonProps {
  menuShown: boolean;
  setMenuShown: (menuShown: boolean) => void;
}

const SideMenuButton = ({ menuShown, setMenuShown }: SideMenuButtonProps) => {
  const [isHovered, setIsHovered] = useState(false);

  return (
    <Button
      size={"sm"}
      variant={"outline"}
      className={"relative px-[15px] flex items-center justify-center"}
      onMouseEnter={() => setIsHovered(true)}
      onMouseLeave={() => setIsHovered(false)}
      onClick={() => {
        setMenuShown(!menuShown);
        setIsHovered(false);
      }}
    >
      {!menuShown && (
        <div className={"absolute flex items-center justify-center"}>
          <Menu
            className={`absolute w-8 transition-opacity duration-300 ease-in-out ${
              isHovered ? "opacity-0" : "opacity-100"
            }`}
          />
          <ChevronsRightIcon
            className={`absolute w-8 transition-opacity duration-300 ease-in-out ${
              isHovered ? "opacity-100" : "opacity-0"
            }`}
          />
        </div>
      )}
      {menuShown && (
        <ChevronsLeftIcon
          className={`absolute w-8 transition-opacity duration-200 ease-in-out`}
        />
      )}
    </Button>
  );
};

export default SideMenuButton;
