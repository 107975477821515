import {
  Popover,
  PopoverContent,
  PopoverTrigger,
} from "@/components/ui/popover.tsx";
import { InfoCircledIcon } from "@radix-ui/react-icons";
import { PropsWithChildren, useState } from "react";

interface InfoPopoverProps extends PropsWithChildren {}

const InfoPopover = ({ children }: InfoPopoverProps) => {
  const [open, setOpen] = useState<boolean>(false);

  const handleMouseEnter = () => {
    setOpen(true);
  };

  const handleMouseLeave = () => {
    setOpen(false);
  };

  return (
    <Popover open={open}>
      <PopoverTrigger
        onMouseEnter={handleMouseEnter}
        onMouseLeave={handleMouseLeave}
      >
        <InfoCircledIcon className={"text-sm mb-[8px] ml-[0px]"} />
      </PopoverTrigger>
      <PopoverContent
        side={"top"}
        onMouseEnter={handleMouseEnter}
        onMouseLeave={handleMouseLeave}
        className="text-sm"
      >
        {children}
      </PopoverContent>
    </Popover>
  );
};

export default InfoPopover;
