import { useCallback, useMemo } from "react";
import { useForm } from "react-hook-form";
import AxiosInstance from "@/services/axios-instance.ts";
import useAuthStore from "@/store/useAuthStore.tsx";
import { toast } from "sonner";

export const useChangePassword = () => {
  const { setToken } = useAuthStore();
  const form = useForm<{
    currentPassword: string;
    newPassword: string;
    newPasswordConfirmation: string;
  }>({
    mode: "onSubmit",
    shouldFocusError: false,
    defaultValues: {
      currentPassword: "",
      newPassword: "",
      newPasswordConfirmation: "",
    },
  });

  const onPasswordChange = useCallback(async () => {
    const { newPassword, currentPassword } = form.getValues();
    const url = `/change_password`;
    try {
      const resp = await AxiosInstance.post(url, {
        current_password: currentPassword,
        new_password: newPassword,
      });

      toast.success("You are successfully changed password!");

      setToken({
        accessToken: resp?.data?.access_token,
        refreshToken: resp?.data?.refresh_token,
      });
    } catch (error) {
      console.error("change pass error");

      toast.error("Password changing error");
    }

    form.reset({
      currentPassword: "",
      newPasswordConfirmation: "",
      newPassword: "",
    });
  }, [form, setToken]);

  return useMemo(
    () => ({
      form,
      onSubmit: form.handleSubmit(onPasswordChange),
    }),
    [form, onPasswordChange],
  );
};
