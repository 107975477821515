import {
  Pagination,
  PaginationContent,
  PaginationEllipsis,
  PaginationItem,
  PaginationLink,
  PaginationNext,
  PaginationPrevious,
} from "@/components/ui/pagination.tsx";
import { useEffect, useState } from "react";
import {
  Popover,
  PopoverContent,
  PopoverTrigger,
} from "@/components/ui/popover.tsx";
import { Input } from "@/components/ui/input.tsx";
import { Button } from "@/components/ui/button.tsx";

export const TablePagination = ({
  totalPagesCount = 1,
  currentPaginationPage = null,
  moveToPaginationPage,
  trendType = "trends",
}) => {
  const [customPage, setCustomPage] = useState(totalPagesCount);

  return (
    <div>
      <Pagination>
        <PaginationContent>
          <PaginationItem>
            <PaginationPrevious
              className={`cursor-pointer h-8 ${currentPaginationPage === 1 && "!text-gray-400"} `}
              onClick={() => {
                if (currentPaginationPage - 1 < 1) return;
                moveToPaginationPage(currentPaginationPage - 1);
              }}
            />
          </PaginationItem>

          <PaginationItem>
            <PaginationLink className={"h-8 pointer-events-none cursor-none"}>
              {currentPaginationPage}
            </PaginationLink>
          </PaginationItem>

          <PaginationItem className={"cursor-pointer"}>
            <Popover>
              <PopoverTrigger>
                <PaginationEllipsis />
              </PopoverTrigger>
              <PopoverContent
                className={"z-40 "}
                avoidCollisions
                side={"bottom"}
                sticky="always"
              >
                <div className={"flex flex-col gap-[16px]"}>
                  <Input
                    max={totalPagesCount}
                    placeholder={`custom page, max ${totalPagesCount}`}
                    type={"number"}
                    onChange={(event) => {
                      const page = Number(event.target.value);
                      setCustomPage(page);
                    }}
                  />
                  {customPage !== null && customPage !== 0 && (
                    <Button
                      size={"sm"}
                      disabled={
                        customPage === currentPaginationPage ||
                        customPage > totalPagesCount
                      }
                      variant={"outline"}
                      onClick={() => {
                        moveToPaginationPage(customPage);
                      }}
                    >
                      Move to page {customPage}
                    </Button>
                  )}

                  <div className={"flex gap-[16px]"}>
                    <Button
                      size={"sm"}
                      className={"w-[50%]"}
                      disabled={1 === currentPaginationPage}
                      variant={"outline"}
                      onClick={() => {
                        moveToPaginationPage(1);
                      }}
                    >
                      Page 1
                    </Button>
                    <Button
                      className={"w-[50%]"}
                      size={"sm"}
                      disabled={totalPagesCount === currentPaginationPage}
                      variant={"outline"}
                      onClick={() => {
                        moveToPaginationPage(totalPagesCount);
                      }}
                    >
                      Page {totalPagesCount}
                    </Button>
                  </div>
                </div>
              </PopoverContent>
            </Popover>
          </PaginationItem>

          <PaginationItem>
            <PaginationNext
              className={`cursor-pointer h-8 ${currentPaginationPage === totalPagesCount && "!text-gray-400"}`}
              onClick={() => {
                if (currentPaginationPage + 1 > totalPagesCount) return;
                moveToPaginationPage(currentPaginationPage + 1);
              }}
            />
          </PaginationItem>
        </PaginationContent>
      </Pagination>
    </div>
  );
};
