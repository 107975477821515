import { Button } from "@/components/ui/button.tsx";
import { useNavigate } from "react-router-dom";
import { ChevronLeft } from "lucide-react";
import { useMe } from "@/hooks/useMe.tsx";
import { toast } from "sonner";

const CancelNewsletterPage = () => {
  const navigation = useNavigate();
  const { me } = useMe({});

  const handleBack = () => {
    navigation(-1);
  };

  async function handleSubmit() {
    if (!me?.email) return;

    try {
      const response = await fetch(
        "https://amazon-insights-8ci9.onrender.com/unsubscribe-from-newsletters",
        {
          method: "POST",
          headers: {
            "Content-Type": "application/json",
          },
          body: JSON.stringify({ email: me.email }),
        },
      );
      if (response.ok) {
        toast.success("You successfully unsubscribed!");
        navigation(-1);
      }
    } catch (error) {
      console.error("Subscription error:", error);

      toast.error("Something went wrong. Please try again later.");
    }
  }

  return (
    <div className="w-full h-screen bg-white flex items-center justify-center">
      <div className="relative flex flex-col items-center justify-center">
        <ChevronLeft
          width={28}
          height={28}
          className="absolute -top-32 -left-2 text-text-2 transition hover:text-gray-300 cursor-pointer"
          onClick={handleBack}
        />
        <p className="text-h3">Are you sure you want to unsubscribe?</p>
        <p className="text-h5 text-gray-500">
          Press this button to unsubscribe:
        </p>
        <div className="flex flex-row items-center justify-center mt-2">
          <Button variant="exit" onClick={handleSubmit}>
            Unsubscribe
          </Button>
        </div>
      </div>
    </div>
  );
};

export default CancelNewsletterPage;
