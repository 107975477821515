import { type ClassValue, clsx } from "clsx";
import { twMerge } from "tailwind-merge";
import { format } from "date-fns";

export function cn(...inputs: ClassValue[]) {
  return twMerge(clsx(inputs));
}

export const focusInput = [
  // base
  "focus:ring-2",
  // ring color
  "focus:ring-blue-200 focus:dark:ring-blue-700/30",
  // border color
  "focus:border-blue-500 focus:dark:border-blue-700",
];

// Tremor Raw focusRing [v0.0.1]

export const focusRing = [
  // base
  "outline outline-offset-2 outline-0 focus-visible:outline-2",
  // outline color
  "outline-blue-500 dark:outline-blue-500",
];

// Tremor Raw hasErrorInput [v0.0.1]

export const hasErrorInput = [
  // base
  "ring-2",
  // border color
  "border-red-500 dark:border-red-700",
  // ring color
  "ring-red-200 dark:ring-red-700/30",
];

export const PERCENT_VALUES = [
  "half_year_ratio",
  "click_share_1",
  "click_share_2",
  "click_share_3",
  "click_share_sum",
  "conv_share_1",
  "conv_share_2",
  "conv_share_3",
  "convershion_share_sum",
  "total_click_share",
];

export function formatDateToMM(dateStr: string): string {
  const date = new Date(dateStr);
  return format(date, " dd MMM yy");
}

export function formatNumber({
  number,
  isPercentage = false,
}: {
  number: string | number;
  isPercentage?: boolean;
}) {
  const numericValue = typeof number === "string" ? parseFloat(number) : number;

  const absValue = Math.abs(numericValue);
  const suffixes = ["", "K", "M", "B", "T"];
  const tier = (Math.log10(absValue) / 3) | 0;

  if (tier >= suffixes.length) {
    return numericValue.toExponential(2);
  }

  const scaled = absValue / Math.pow(1000, tier);
  const formatted = scaled.toFixed();

  if (isPercentage) {
    if (numericValue === -1) return "-";

    return `${numericValue < 0 ? "-" : ""}${formatted}${suffixes[tier]}%`;
  } else {
    return `${numericValue < 0 ? "-" : ""}${formatted}${suffixes[tier]}`;
  }
}

export function isObject(value) {
  return typeof value === "object" && value !== null && !Array.isArray(value);
}

export function isEmptyObject(obj) {
  return Object.keys(obj).length === 0;
}
