import { toast } from "sonner";
import { ListType } from "@/hooks/usePaginatedTrends/usePaginatedTrends.tsx";
import { useQueryClient } from "@tanstack/react-query";
import AxiosInstance from "@/services/axios-instance.ts";
import { useOnboardingStore } from "@/components/onboarding/useOboardingStore.tsx";
import {
  checkStepPositionByName,
  ONBOARDING_CLASSES,
} from "@/components/onboarding/useOnboarding.tsx";

export const useUserSettings = () => {
  const queryClient = useQueryClient();

  const addTrendToBlockList = async ({
    trends_ids,
  }: {
    trends_ids: string[];
  }) => {
    const url = `/set-black-list`;

    try {
      await AxiosInstance.post<{
        trends_ids: string[];
      }>(url, {
        trends_ids,
      });
      toast.success("Blacklist updated");

      await queryClient.cancelQueries({ queryKey: ["me"] });
      queryClient.setQueryData(["me"], (old) => ({
        ...old,
        black_list: [...old?.black_list, ...trends_ids],
      }));
    } catch (error) {
      toast.error(error.response.data.message);
    }
  };

  const removeTrendsByListName = async ({
    trends_ids,
    listType,
  }: {
    trends_ids: string[];
    listType: ListType;
  }) => {
    const url = `/remove-from-list-by-list-name`;

    try {
      await AxiosInstance.post<{
        trends_ids: string[];
        list_type: ListType;
      }>(url, {
        trends_ids,
        list_type: listType,
      });

      const list = listType === "watch" ? "watch_list" : "black_list";
      await queryClient.cancelQueries({ queryKey: ["me"] });

      queryClient.setQueryData(["me"], (old) => {
        const updatedList = old?.[list]?.filter(
          (item) => !trends_ids.includes(item),
        );
        return {
          ...old,
          [list]: updatedList,
        };
      });

      toast.success(`${listType}list updated`);
    } catch (error) {
      toast.error(error.response.data.message);
    }
  };

  const addTrendToWatchList = async ({
    trends_ids,
  }: {
    trends_ids: string[];
  }) => {
    const url = `/set-watch-list`;
    const { onboardingCanShow } = useOnboardingStore.getState();

    try {
      await AxiosInstance.post<{
        trends_ids: string[];
      }>(url, {
        trends_ids,
      });
      await queryClient.cancelQueries({ queryKey: ["me"] });
      queryClient.setQueryData(["me"], (old) => ({
        ...old,
        watch_list: [...old?.watch_list, ...trends_ids],
      }));

      if (!onboardingCanShow) {
        toast.success("Watchlist updated");
      }
    } catch (error) {
      toast.error(error?.response?.data?.message);
    }
  };

  const addFiltersList = async ({ filterList }: { filterList: any[] }) => {
    const url = `/set-filter-list`;

    try {
      await AxiosInstance.post<{
        trends_ids: string[];
      }>(url, {
        filter_list: filterList,
      });
    } catch (error) {
      toast.warning(error?.response?.data?.message || "Set filters error");
    } finally {
      await queryClient.refetchQueries({ queryKey: ["me"] });
    }
  };

  const addNewSavedSearch = async ({
    saveSearch,
    name = "",
  }: {
    saveSearch: any[];
    name?: string;
  }) => {
    const url = `/add-new-saved-search`;

    try {
      await AxiosInstance.post<{
        saved_search: any[];
        name?: string;
      }>(url, {
        saved_search: saveSearch,
        name,
      });
      toast.message("Saved search added");
    } catch (error) {
      toast.error(error?.response?.data?.message);
    } finally {
      await queryClient.refetchQueries({ queryKey: ["me"] });
    }
  };

  const deleteSavedSearchById = async ({ id }: { id: string }) => {
    const url = `/delete-saved-search-by-id`;

    try {
      await AxiosInstance.post(url, {
        id,
      });
      toast.message(`Saved search deleted`);
    } catch (error) {
      toast.error(
        error?.response?.data?.message || "Error deleting saved search",
      );
    } finally {
      await queryClient.refetchQueries({ queryKey: ["me"] });
    }
  };

  return {
    addTrendToBlockList,
    addTrendToWatchList,
    addFiltersList,
    removeTrendsByListName,
    addNewSavedSearch,
    deleteSavedSearchById,
  };
};
