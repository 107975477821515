import { Button } from "@/components/ui/button.tsx";
import { FC, useState } from "react";
import { Drawer } from "vaul";

import { DialogClose } from "@/components/ui/dialog.tsx";

import { FilterContainer } from "@/components/filters/FilterContainer/FilterContainer.tsx";
import { MinMaxInput } from "@/components/filters/MinMaxInput/MinMaxInput.tsx";
import { QueryFilter } from "@/components/filters/QueryFilter/QueryFilter.tsx";
import { OptionFilterReturn } from "@/pages/TrendsPage/TrendsPage.tsx";
import { ScrollArea } from "@/components/ui/scroll-area.tsx";
import { SwitchFilter } from "@/components/filters/SwtichFilter/SwitchFilter.tsx";
import { CategoryFilter } from "@/components/filters/CategoryFilter/CategoryFilter.tsx";

import { SaveSearchDialog } from "@/components/dialogs/SaveSearchDialog.tsx";
import { FilterIcon } from "lucide-react";
import Lottie from "lottie-react";
import loadingAnimation from "@/animations/loading.json";
import {
  checkStepPositionByName,
  ONBOARDING_CLASSES,
} from "@/components/onboarding/useOnboarding.tsx";
import { useOnboardingStore } from "@/components/onboarding/useOboardingStore.tsx";

type TrendFiltersDialogProps = {
  addFilters: ({ filters }: { filters: OptionFilterReturn[] }) => void;
  onSearch: () => void;
  savedSearchFilters: any[];
  disabled: boolean;
  isLoading?: boolean;
};

export const TableFilterDialog: FC<TrendFiltersDialogProps> = ({
  addFilters,
  onSearch,
  savedSearchFilters,
  disabled,
  isLoading = false,
}) => {
  const { nextStep, onboardingCanShow, currentOnboardingStep } =
    useOnboardingStore();
  const [dropFilters, setDropFilters] = useState(false);

  const [appliedFilters, setAppliedFilters] = useState<OptionFilterReturn[]>(
    [],
  );

  const onSearchHandler = () => {
    nextStep();

    addFilters({ filters: appliedFilters });
    setTimeout(() => {
      onSearch();
      setDropFilters(false);
    }, 500);
  };

  const addFiltersHandler = ({
    filters,
  }: {
    filters: OptionFilterReturn[];
  }) => {
    setAppliedFilters((prevFilters) => {
      const filteredPrevFilters = prevFilters.filter(
        (filter) =>
          !filters.some((newFilter) => newFilter.queryKey === filter.queryKey),
      );

      const validNewFilters = filters.filter(
        (filter) =>
          filter.filterValue !== null &&
          filter.filterValue !== "" &&
          filter.filterValue !== false,
      );

      const newF = [...filteredPrevFilters, ...validNewFilters];
      // debouncedSaveFiltersToLocalStorage(newF);
      return newF;
    });
    setDropFilters(false);
  };

  const open =
    ([
      checkStepPositionByName(ONBOARDING_CLASSES.wholeFilterModalHighlight),
      checkStepPositionByName(ONBOARDING_CLASSES.saveSearchHighlight),
      checkStepPositionByName(ONBOARDING_CLASSES.showResultsHighlight),
    ].includes(currentOnboardingStep) &&
      onboardingCanShow) ||
    (!onboardingCanShow && undefined);

  return (
    <Drawer.Root
      open={open}
      direction="right"
      className="bg-white"
      dismissible={!onboardingCanShow}
      modal={!onboardingCanShow}
    >
      <Drawer.Trigger asChild>
        <Button
          variant="outline"
          size={"sm"}
          className={`${ONBOARDING_CLASSES.step11}`}
          onClick={nextStep}
        >
          <FilterIcon className={"mr-[6px] w-4 h-4"} strokeWidth={1} />
          Filters
        </Button>
      </Drawer.Trigger>
      <Drawer.Portal>
        <Drawer.Overlay className="fixed inset-0 bg-black/70 z-[30]" />
        <Drawer.Content
          className={`py-6 pl-6 bg-white flex flex-col rounded-t-[10px] rounded-b-[10px] h-full w-1/2 fixed bottom-0 right-0 z-30 `}
        >
          <ScrollArea>
            <div
              className={`flex flex-col gap-6 mr-4 ${ONBOARDING_CLASSES.wholeFilterModalHighlight}`}
            >
              <div className={"flex w-full gap-4 flex-col"}>
                <p className="font-bold">Search Term</p>
                <FilterContainer>
                  <CategoryFilter
                    selectedSavedSearch={savedSearchFilters}
                    queryKey={"category_list"}
                    dropFilter={dropFilters}
                    onSave={addFiltersHandler}
                  />
                </FilterContainer>

                {/*<FilterContainer>*/}
                {/*  <SwitchFilter*/}
                {/*    selectedSavedSearch={savedSearchFilters}*/}
                {/*    dropFilter={dropFilters}*/}
                {/*    reversed*/}
                {/*    queryKey={"adult_product"}*/}
                {/*    onSelect={addFiltersHandler}*/}
                {/*  />*/}
                {/*</FilterContainer>*/}
                <FilterContainer>
                  <SwitchFilter
                    selectedSavedSearch={savedSearchFilters}
                    dropFilter={dropFilters}
                    queryKey={"new_key"}
                    popoverContent={
                      "Display only search terms that have appeared for the first time within the last year."
                    }
                    onSelect={addFiltersHandler}
                  />
                </FilterContainer>
              </div>

              <div className={"w-full  flex "}>
                <QueryFilter
                  selectedSavedSearch={savedSearchFilters}
                  queryKey={"search_term"}
                  dropFilter={dropFilters}
                  onSearchQuery={addFiltersHandler}
                />
              </div>
              <div className="pt-4">Popularity analysis</div>
              <div className={"flex w-full gap-[40px]"}>
                <FilterContainer>
                  <MinMaxInput
                    selectedSavedSearch={savedSearchFilters}
                    queryKey={"avg_ly_sv"}
                    dropFilter={dropFilters}
                    onSave={addFiltersHandler}
                    infoPopoverText="Average weekly number of searches for a term over the past 12 months."
                  >
                    Average last year search volume
                  </MinMaxInput>
                </FilterContainer>
                <FilterContainer>
                  <MinMaxInput
                    selectedSavedSearch={savedSearchFilters}
                    queryKey={"last_week_rank"}
                    dropFilter={dropFilters}
                    onSave={addFiltersHandler}
                    infoPopoverText="The total number of searches for a term during the previous 7 days."
                  >
                    Last Week Search Volume
                  </MinMaxInput>
                </FilterContainer>
              </div>

              <div className={"flex  flex-col gap-[32px] "}>
                <div className={"flex w-full flex-wrap gap-10 "}>
                  <FilterContainer>
                    <MinMaxInput
                      selectedSavedSearch={savedSearchFilters}
                      queryKey={"sv_increase_12_mnth_abs"}
                      dropFilter={dropFilters}
                      onSave={addFiltersHandler}
                      infoPopoverText="Numerical change in the total number of searches over the past 12 months (0-12 months ago) compared to the prior 12 months (12-24 months ago)."
                    />
                  </FilterContainer>
                  <FilterContainer>
                    <MinMaxInput
                      selectedSavedSearch={savedSearchFilters}
                      queryKey={"sv_increase_12_mnth_ratio"}
                      dropFilter={dropFilters}
                      onSave={addFiltersHandler}
                      infoPopoverText="Percentage change in total searches over the past 12 months (0-12 months ago) compared to the prior 12 months (12-24 months ago)."
                    />
                  </FilterContainer>
                </div>
                <div className={"flex w-full gap-10"}>
                  <FilterContainer>
                    <MinMaxInput
                      selectedSavedSearch={savedSearchFilters}
                      queryKey={"sv_increase_6_mnth_abs"}
                      dropFilter={dropFilters}
                      onSave={addFiltersHandler}
                      infoPopoverText="Numerical change in the total number of searches over the past 6 months (0-6 months ago) compared to the prior 6 months (6-12 months ago)."
                    />
                  </FilterContainer>
                  <FilterContainer>
                    <MinMaxInput
                      selectedSavedSearch={savedSearchFilters}
                      queryKey={"sv_increase_6_mnth_ratio"}
                      dropFilter={dropFilters}
                      onSave={addFiltersHandler}
                      infoPopoverText="Percentage change in total searches over the past 6 months (0-6 months ago) compared to the prior 6 months (6-12 months ago)."
                    />
                  </FilterContainer>
                </div>
                <div className={"flex w-full gap-10"}>
                  <FilterContainer>
                    <MinMaxInput
                      selectedSavedSearch={savedSearchFilters}
                      queryKey={"sv_increase_3_mnth_abs"}
                      dropFilter={dropFilters}
                      onSave={addFiltersHandler}
                      infoPopoverText="Numerical change in the total number of searches over the past 3 months (0-3 months ago) compared to the prior 3 months (3-6 months ago)."
                    />
                  </FilterContainer>
                  <FilterContainer>
                    <MinMaxInput
                      selectedSavedSearch={savedSearchFilters}
                      queryKey={"sv_increase_3_mnth_ratio"}
                      dropFilter={dropFilters}
                      onSave={addFiltersHandler}
                      infoPopoverText="Percentage change in total searches over the past 3 months (0-3 months ago) compared to the prior 3 months (3-6 months ago)."
                    />
                  </FilterContainer>
                </div>
              </div>

              <div className="pt-4">First page data</div>
              <div className={"flex w-full gap-10"}>
                <FilterContainer>
                  <MinMaxInput
                    selectedSavedSearch={savedSearchFilters}
                    queryKey={"reviews_mean"}
                    onSave={addFiltersHandler}
                    dropFilter={dropFilters}
                    infoPopoverText="Average number of customer reviews for products listed on the first page of search results."
                  >
                    Average Reviews
                  </MinMaxInput>
                </FilterContainer>
                <FilterContainer>
                  <MinMaxInput
                    selectedSavedSearch={savedSearchFilters}
                    queryKey={"reviews_median"}
                    onSave={addFiltersHandler}
                    dropFilter={dropFilters}
                    infoPopoverText="Median number of customer reviews for products on the first search page. Half of the products have more reviews and half have fewer."
                  >
                    Median Reviews
                  </MinMaxInput>
                </FilterContainer>
              </div>

              <div className={"flex w-full gap-10"}>
                <FilterContainer>
                  <MinMaxInput
                    selectedSavedSearch={savedSearchFilters}
                    queryKey={"price_mean"}
                    onSave={addFiltersHandler}
                    dropFilter={dropFilters}
                    infoPopoverText="Average price of products listed on the first page of search results."
                  >
                    Average Price
                  </MinMaxInput>
                </FilterContainer>

                <FilterContainer>
                  <MinMaxInput
                    selectedSavedSearch={savedSearchFilters}
                    queryKey={"price_median"}
                    onSave={addFiltersHandler}
                    dropFilter={dropFilters}
                    infoPopoverText="Median price of products on the first search page, where half of the products are priced higher and half are priced lower."
                  >
                    Median Price
                  </MinMaxInput>
                </FilterContainer>
              </div>

              <div className={"flex w-full gap-10"}>
                <FilterContainer>
                  <MinMaxInput
                    selectedSavedSearch={savedSearchFilters}
                    queryKey={"rating_median"}
                    onSave={addFiltersHandler}
                    dropFilter={dropFilters}
                    infoPopoverText="Median rating value for products on the first search page, where half of the products have a higher rating and half have a lower rating."
                  >
                    Rating Median
                  </MinMaxInput>
                </FilterContainer>
                <FilterContainer>
                  <MinMaxInput
                    selectedSavedSearch={savedSearchFilters}
                    queryKey={"rating_mean"}
                    onSave={addFiltersHandler}
                    dropFilter={dropFilters}
                    infoPopoverText="Average customer rating for products on the first search page."
                  >
                    Rating Average
                  </MinMaxInput>
                </FilterContainer>
              </div>
              {/*TODO: rollback it when fixed, COLUMNS SETTINGS CHECK TOO*/}
              {/*<div className={"flex w-full gap-10"}>*/}
              {/*  <FilterContainer>*/}
              {/*    <MinMaxInput*/}
              {/*      selectedSavedSearch={savedSearchFilters}*/}
              {/*      queryKey={"global_search_count"}*/}
              {/*      onSave={addFiltersHandler}*/}
              {/*      dropFilter={dropFilters}*/}
              {/*    />*/}
              {/*  </FilterContainer>*/}
              {/*</div>*/}
              <div className="pt-4">Top-3 products</div>
              <div className={"flex w-full gap-10 mb-[36px]"}>
                <FilterContainer>
                  <MinMaxInput
                    selectedSavedSearch={savedSearchFilters}
                    queryKey={"click_share_sum"}
                    dropFilter={dropFilters}
                    onSave={addFiltersHandler}
                    infoPopoverText="Percentage of total clicks that the top three products receive."
                  >
                    Top 3 Products Click Share
                  </MinMaxInput>
                </FilterContainer>
                <FilterContainer>
                  <MinMaxInput
                    selectedSavedSearch={savedSearchFilters}
                    queryKey={"convershion_share_sum"}
                    dropFilter={dropFilters}
                    onSave={addFiltersHandler}
                    infoPopoverText="Percentage of total purchases attributed to the top three products."
                  >
                    Top 3 Products Conversion Share
                  </MinMaxInput>
                </FilterContainer>
              </div>
            </div>
          </ScrollArea>

          <div className={"flex w-full justify-between pt-6"}>
            <Button
              size={"sm"}
              variant={"outline"}
              onClick={() => {
                setDropFilters(true);
                // setSavedSearchFilters([]);
              }}
            >
              Clear all
            </Button>

            <div className={"flex gap-[16px] pr-6"}>
              <SaveSearchDialog appliedFilters={appliedFilters} />

              <DialogClose asChild>
                <Button
                  className={`w-[150px] ${ONBOARDING_CLASSES.showResultsHighlight}`}
                  size={"sm"}
                  onClick={onSearchHandler}
                  variant="accent"
                >
                  Show Results
                </Button>
              </DialogClose>
            </div>
          </div>
        </Drawer.Content>
      </Drawer.Portal>
    </Drawer.Root>
  );
};
