import { Navigate, Outlet, useNavigate } from "react-router-dom";
import useAuthStore from "@/store/useAuthStore.tsx";
import { Button } from "@/components/ui/button.tsx";
import { useMe } from "@/hooks/useMe.tsx";
import useMenuStore from "@/store/useMenuStore";
import { ArchiveX, Bolt, ChartNoAxesCombined } from "lucide-react";
import { Badge } from "@/components/ui/badge.tsx";
import NavButton from "@/components/NavButton.tsx";
import { Skeleton } from "@/components/ui/skeleton.tsx";
import { ExitIcon, HeartIcon, ListBulletIcon } from "@radix-ui/react-icons";
import { ONBOARDING_CLASSES } from "@/components/onboarding/useOnboarding.tsx";
import { IS_PROD } from "@/consts/consts.ts";
import Intercom from "@intercom/messenger-js-sdk";

export const UserLayout = () => {
  const { accessToken } = useAuthStore();
  const isAuthenticated = accessToken !== "";
  const { clearToken } = useAuthStore();
  const navigation = useNavigate();
  const { menuShown } = useMenuStore();
  const { me } = useMe({});

  const signOut = () => {
    clearToken();
    navigation("/");
  };

  if (!isAuthenticated) {
    return <Navigate to="/" replace />;
  }

  if (IS_PROD && me?._id) {
    Intercom({
      app_id: import.meta.env.VITE_INTERCOM_ID,
      name: me?.email,
      email: me?.email,
    });
  }
  return (
    <div className="flex w-full h-full">
      <div
        className={`flex ${
          !menuShown && "hidden"
        } bg-white max-w-[220px] border-r-[1px] border-r-gray-300 min-w-[220px] flex-col p-[16px] justify-between h-full min-h-screen`}
      >
        <div className="flex flex-col gap-6 text-black">
          <div className="flex flex-row justify-between">
            {me?.email === undefined ? (
              <Skeleton className={"w-28 h-6"} />
            ) : (
              <div>
                <p className="font-medium text-ellipsis overflow-hidden truncate max-w-32 ...">
                  Hi, {me?.email && me.email.split("@")[0]}
                </p>
              </div>
            )}

            {me?.subscription === undefined ? (
              <Skeleton className={"w-12 h-6"} />
            ) : (
              <Badge
                className={`w-12 h-6 px-1.5 rounded-full items-center pointer-events-none shadow-none justify-center ${
                  me?.subscription
                    ? "text-green bg-green-soft"
                    : "bg-red-soft text-red"
                }`}
              >
                {me?.subscription ? "Active" : "Inactive"}
              </Badge>
            )}
          </div>
          <div
            className={`flex flex-col gap-1 ${ONBOARDING_CLASSES.blackListMenuHighlight}`}
          >
            <NavButton to="/trends" icon={ChartNoAxesCombined}>
              Trends
            </NavButton>
            <>
              <NavButton to="/watchlist" icon={HeartIcon}>
                Watchlist
                <span className={"text-xs text-gray-400"}>
                  {me?.watch_list.length}
                </span>
              </NavButton>

              <NavButton to="/blacklist" icon={ArchiveX}>
                Blacklist{" "}
                <span className={"text-xs text-gray-400"}>
                  {me?.black_list.length}
                </span>
              </NavButton>
            </>

            <NavButton to="/billing" icon={ListBulletIcon}>
              Billing
            </NavButton>
            <NavButton to="/settings" icon={Bolt}>
              Settings
            </NavButton>
          </div>
        </div>
        <div className="w-full flex flex-col gap-2">
          <p className="text-sm self-center text-center text-gray-500 overflow-hidden truncate max-w-40 ...">
            {me?.email}
          </p>
          <Button
            variant="exit"
            size="sm"
            onClick={signOut}
            className="flex items-center justify-center gap-1"
          >
            <ExitIcon />
            <span className="pt-0.5">Log out</span>
          </Button>
        </div>
      </div>

      <div className="flex w-full overflow-hidden">
        <Outlet />
      </div>
    </div>
  );
};
