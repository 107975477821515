export const API_URl = import.meta.env.VITE_API_URL;

export const EMAIL_PATTERN =
  /^(([^<>()[\]\\.,;:\s@\"]+(\.[^<>()[\]\\.,;:\s@\"]+)*)|(\".+\"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/i;
export const URL_PATTERN =
  /[-a-zA-Z0-9@:%._\+~#=]{1,256}\.[a-zA-Z0-9()]{1,6}\b([-a-zA-Z0-9()@:%_\+.~#?&//=]*)?/gi;
export const PASSWORD_PATTERNS = [
  /^(?=.*[a-z])(?=.*[A-Z])(?=.*\d)(?=.*[~#^_();:.,@$!%*?&])[A-Za-z\d~#^_();:.,@$!%*?&]{8,}$/,
  /^(?=.*[A-Z])(?=.*\d)(?=.*[~#^_();:.,@$!%*?&])[A-Z\d~#^_();:.,@$!%*?&]{8,}$/,
  /^(?=.*[a-z])(?=.*\d)(?=.*[~#^_();:.,@$!%*?&])[a-z\d~#^_();:.,@$!%*?&]{8,}$/,
  /^(?=.*[a-z])(?=.*[A-Z])(?=.*[~#^_();:.,@$!%*?&])[A-Za-z~#^_();:.,@$!%*?&]{8,}$/,
  /^(?=.*[a-z])(?=.*[A-Z])(?=.*\d)[A-Za-z\d]{8,}$/,
];

export const IS_STAGING = import.meta.env.VITE_ENV === "staging";
export const IS_PROD = import.meta.env.VITE_ENV === "production";
