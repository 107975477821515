import { ArrowRight } from "lucide-react";
import { Button } from "@/components/ui/button.tsx";
import { useCallback, useEffect, useState } from "react";
import { initializePaddle, Paddle } from "@paddle/paddle-js";
import { useMe } from "@/hooks/useMe.tsx";

const PricingModal = () => {
  const { me } = useMe({});
  const [savedPayment, setSavedPayment] = useState(null);

  const getPriceId = useCallback(() => {
    if (!savedPayment) return "";
    const dev = {
      yearly: "pri_01j6254vaenyb5tn1vbzwcjaya",
      monthly: "pri_01j6253e8cex6rxj46mhwe3jfd",
    };

    const prod = {
      yearly: "pri_01j624sw107gqf66bkrz74ydkk",
      monthly: "pri_01j624vagdt9c4v4n34p7vsth7",
    };

    return import.meta.env.VITE_ENV === "production"
      ? prod[savedPayment]
      : dev[savedPayment];
  }, [savedPayment]);

  useEffect(() => {
    if (savedPayment !== null) {
      const isProd = import.meta.env.VITE_ENV === "production";

      //TODO: after payment we need to reload page or navigate user
      initializePaddle({
        environment: isProd ? "production" : "sandbox",
        token: import.meta.env.VITE_PADDLE_TOKEN,
        checkout: {
          settings: {
            displayMode: "overlay",
            successUrl: isProd
              ? "https://app.nextbigniche.ai/success-payment"
              : "https://staging-app.nextbigniche.ai/success-payment",
            showAddDiscounts: true,
            showAddTaxId: false,
          },
        },
      }).then((paddleInstance: Paddle | undefined) => {
        const priceId = getPriceId();
        paddleInstance?.Checkout.open({
          customer: {
            email: me?.email,
          },
          settings: {
            theme: "light",
          },
          customData: {
            email: me?.email,
          },
          items: [{ priceId, quantity: 1 }],
        });
      });
    }
  }, [getPriceId, me?.email, savedPayment]);

  const text = me?.trial_used
    ? "Start new subscription"
    : "Start 7-day trial for free";
  return (
    <div className=" flex w-full top-[20%] items-center justify-center">
      <div className="relative z-50 flex w-full h-full items-center">
        <div className="bg-grey-hues w-full h-full xl:h-[26rem] xl:block absolute z-40" />
        <div className="xl:container xl:px-24 w-full justify-center px-4 flex flex-col xl:flex-row items-center xl:justify-start gap-8 z-50">
          <div className="flex w-full flex-col gap-6 justify-center z-50">
            <p className="text-h3 font-bold xl:text-start text-center z-50">
              You need an active subscription to continue
            </p>
            <div className="text-text-18-semibold flex flex-col gap-4 z-50">
              <p className="text-h5 font-medium text-gray-400">
                Subscription summary:
              </p>
              <div className="flex flex-row gap-2">
                <ArrowRight className="min-h-6 min-w-6" />
                <p>
                  Discover emerging niches with high margin and low entry cost
                  with unlimited access to 120,000 market trends
                </p>
              </div>
              <div className="flex flex-row gap-2">
                <ArrowRight className="min-h-6 min-w-6" />
                <p>
                  Easily navigate market changes with data updates every week
                </p>
              </div>
              <div className="flex flex-row gap-2">
                <ArrowRight className="min-h-6 min-w-6" />
                <p>Monitor trend performance before you commit</p>
              </div>
              <div className="flex flex-row gap-2">
                <ArrowRight className="min-h-6 min-w-6" />
                <p>Customize your search to see only what’s relevant</p>
              </div>
            </div>
          </div>
          <div className="flex flex-col sm:flex-row gap-8 z-50">
            <div className="flex flex-col justify-between w-[322px] h-[466px] p-9 bg-white-hues shadow-custom rounded-md ">
              <p className="font-arvo text-h2 font-bold text-center">
                Monthly subscription
              </p>
              <p className="text-text-16-medium text-center">
                Launch your next product. Pay as you go, cancel anytime
              </p>
              <div className="flex flex-col gap-2">
                <p className="font-arvo text-h1 font-bold text-center pb-5">
                  99$<span className="text-h5">/ month</span>
                </p>

                <Button
                  className="w-full"
                  variant="default"
                  onClick={() => setSavedPayment("monthly")}
                >
                  {text}
                </Button>
              </div>
            </div>
            <div className="relative z-50 flex flex-col justify-between w-[322px] h-[466px] p-9 bg-white-hues shadow-custom rounded-md overflow-hidden ">
              <div className="-right-20 top-6 absolute flex w-[240px] h-9 bg-[#FFA7C5] items-center justify-center rotate-45">
                <p className="text-text-16-bold text-accent-2">save 20%</p>
              </div>
              <p className="font-arvo text-h2 font-bold text-center">
                Yearly subscription
              </p>
              <p className="text-text-16-medium text-center">
                Spot new opportunities before everyone else and beat the market.
              </p>
              <div className="flex flex-col gap-[10px]">
                <div>
                  <p className="font-arvo text-h1 font-bold text-center">
                    79$<span className="text-h5">/ month</span>
                  </p>
                  <p className="text-text-16-bold text-text-2 text-center h-5">
                    Billed annually at 948$
                  </p>
                </div>

                <Button
                  className="w-full"
                  variant="default"
                  onClick={() => setSavedPayment("yearly")}
                >
                  {text}
                </Button>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default PricingModal;
