import {
  Popover,
  PopoverContent,
  PopoverTrigger,
} from "@/components/ui/popover";
import { FC, PropsWithChildren } from "react";
import { Button } from "@/components/ui/button.tsx";
import { PopoverClose } from "@radix-ui/react-popover";

interface ConfirmationPopupProps extends PropsWithChildren {
  text: string;
  confirmCb: () => void;
  disabled?: boolean;
}

export const ConfirmationPopup: FC<ConfirmationPopupProps> = ({
  children,
  text,
  confirmCb,
  disabled = false,
}) => {
  return (
    <Popover>
      <PopoverTrigger disabled={disabled}>{children}</PopoverTrigger>
      <PopoverContent>
        <div className={"flex flex-col "}>
          {text}
          <div className={"flex flex-row-reverse justify-between   w-full"}>
            <PopoverClose asChild>
              <Button
                size={"sm"}
                className={"z-40"}
                variant="accent"
                onClick={confirmCb}
              >
                Confirm
              </Button>
            </PopoverClose>
          </div>
        </div>
      </PopoverContent>
    </Popover>
  );
};
