import {
  DropdownMenu,
  DropdownMenuCheckboxItem,
  DropdownMenuContent,
  DropdownMenuLabel,
  DropdownMenuTrigger,
} from "@/components/ui/dropdown-menu.tsx";
import { FC, PropsWithChildren, useEffect, useState } from "react";
import { SortColumnOption } from "@/pages/TrendsPage/TrendsPage.tsx";
import { ClientFilters } from "@/hooks/usePaginatedTrends/usePaginatedTrends.tsx";
import { useOnboardingStore } from "@/components/onboarding/useOboardingStore.tsx";

type OptionsType = "AZ" | "arrow" | "bool";

interface SelectFilterProps extends PropsWithChildren {
  options?: OptionsType;
  onSelect: ({ sort }: { sort: SortColumnOption[] }) => void;
  queryKey: ClientFilters;
  valueLabel?: string;
  labelColor?: string;
  showTriggerContent?: boolean;
  sortable?: boolean;
  currentSort: SortColumnOption[];
}

const LABELS: Record<OptionsType, any[]> = {
  AZ: [
    { label: "A → Z", value: 1 },
    { label: "Z → A", value: -1 },
    { label: "Default", value: 0 },
  ],
  arrow: [
    { label: "Highest first", value: -1 },
    { label: "Lowest first", value: 1 },
    { label: "Default", value: 0 },
  ],
  bool: [
    { label: "New first", value: -1 },
    { label: "New last", value: 1 },
    { label: "Default", value: 0 },
  ],
};

export const SortDropdown: FC<SelectFilterProps> = ({
  queryKey,
  options = "arrow",
  onSelect,
  children,
  valueLabel = "",
  labelColor,
  showTriggerContent = false,
  sortable = true,
  currentSort,
}) => {
  const optionsByOptionsKey = LABELS[options];
  const [isOpen, setIsOpen] = useState(false);
  const { onboardingCanShow } = useOnboardingStore();

  const [defaultValue, setDefaultValue] = useState(optionsByOptionsKey[2]);

  const defaultOption = optionsByOptionsKey.find((o) => o.label === "Default");

  useEffect(() => {
    const key = `${queryKey}-sort`;
    const savedQuery = localStorage.getItem(key);
    if (savedQuery) {
      const parsed = JSON.parse(savedQuery);

      setDefaultValue(parsed);
      onSelect({ sort: [{ columnKey: queryKey, sortValue: parsed.value }] });
    }
  }, []);

  const isOptionSelected = (optionValue: number) => {
    return currentSort.some(
      (sort) => sort.columnKey === queryKey && sort.sortValue === optionValue,
    );
  };

  const getSelectedOption = () => {
    const selectedOption = optionsByOptionsKey.find((o) =>
      isOptionSelected(o.value),
    );
    return selectedOption || defaultOption;
  };

  return (
    <DropdownMenu open={isOpen}>
      <DropdownMenuTrigger onClick={() => setIsOpen(true)} disabled={!sortable}>
        <div className={"flex justify-between items-center"}>
          {showTriggerContent && (
            <div
              className={"flex flex-row text-xs font-medium whitespace-nowrap"}
            >
              <div className="h-5 w-5 flex justify-center items-center transition hover:opacity-60 cursor-pointer">
                <p className={`${labelColor}`}>{valueLabel}</p>
              </div>
            </div>
          )}
          <p className="font-medium">{children}</p>
        </div>
      </DropdownMenuTrigger>
      <DropdownMenuContent
        className={`min-w-[0] ${onboardingCanShow && "z-[4000]"}`}
        onInteractOutside={() => {
          setIsOpen(false);
        }}
      >
        <DropdownMenuLabel>Sort</DropdownMenuLabel>
        {/*<DropdownMenuSeparator />*/}
        {optionsByOptionsKey.map((o, i) => (
          <DropdownMenuCheckboxItem
            key={i}
            checked={o.value === getSelectedOption().value}
            onCheckedChange={() => {
              setDefaultValue(o);
              localStorage.setItem(`${queryKey}-sort`, JSON.stringify(o));

              onSelect({
                sort: [{ columnKey: queryKey, sortValue: o.value }],
              });
            }}
          >
            {o.label || "1"}
          </DropdownMenuCheckboxItem>
        ))}
      </DropdownMenuContent>
    </DropdownMenu>
  );
};
