import { Button } from "@/components/ui/button";
import { ConfirmationPopup } from "@/components/ConfirmationPopup/ConfirmationPopup.tsx";
import { toast } from "sonner";
import AxiosInstance from "@/services/axios-instance.ts";
import { useMe } from "@/hooks/useMe.tsx";
import { initializePaddle, Paddle } from "@paddle/paddle-js";
import { useSubscriptionInfo } from "@/hooks/payment/useSubscriptionInfo.tsx";
import { useQueryClient } from "@tanstack/react-query";
import { usePayment } from "@/hooks/payment/usePayment.tsx";
import { useEffect } from "react";
import useMenuStore from "@/store/useMenuStore.tsx";
export const BillingPage = () => {
  const { updatePayment } = usePayment();

  const queryClient = useQueryClient();
  const { subInfo } = useSubscriptionInfo();
  const { me } = useMe({});

  const showPaymentModal = () => {
    initializePaddle({
      environment:
        import.meta.env.VITE_ENV === "production" ? "production" : "sandbox",
      token: import.meta.env.VITE_PADDLE_TOKEN,
      checkout: {
        settings: {
          locale: "en",
          displayMode: "overlay",
          allowLogout: false,
          // successUrl: "https://app.nextbigniche.ai/trends",
          showAddDiscounts: true,
          showAddTaxId: false,
        },
      },
    }).then(async (paddleInstance: Paddle | undefined) => {
      const transactionId = await updatePayment();
      paddleInstance?.Checkout.open({
        customer: {
          email: me?.email,
        },

        transactionId,
      });
    });
  };

  const { setMenuShown } = useMenuStore();

  useEffect(() => {
    setMenuShown(true);
  }, []);

  // useEffect(() => {
  //   if (me && showUpdatePaymentMethodForm) {
  //     initializePaddle({
  //       environment:
  //         import.meta.env.VITE_ENV === "production" ? "production" : "sandbox",
  //       token: import.meta.env.VITE_PADDLE_TOKEN,
  //       checkout: {
  //         settings: {
  //           locale: "en",
  //           displayMode: "overlay",
  //           allowLogout: false,
  //           // successUrl: "https://app.nextbigniche.ai/trends",
  //           showAddDiscounts: false,
  //         },
  //       },
  //     }).then(async (paddleInstance: Paddle | undefined) => {
  //       const transactionId = await updatePayment();
  //       paddleInstance?.Checkout.open({
  //         customer: {
  //           email: me?.email,
  //         },
  //
  //         transactionId,
  //       });
  //     });
  //   }
  // }, [me, updatePayment, showUpdatePaymentMethodForm]);

  const unsubscribe = async () => {
    const url = `/cancel_subscription`;
    try {
      await AxiosInstance.post(url);
      await queryClient.refetchQueries({ queryKey: ["me"] });
      await queryClient.refetchQueries({ queryKey: ["subscription-info"] });

      toast.success("You are successfully unsubscribed!");
    } catch (error) {
      console.error("signup error", error.response?.data?.message);
      toast.error("Something went wrong");
    }
  };

  const formatDate = (isoDate: string): string => {
    const options = {
      year: "numeric",
      month: "short", // сокращенный месяц (Sep)
      day: "numeric",
      hour: "numeric",
      minute: "numeric",
      hour12: true, // 12-часовой формат
      timeZone: "UTC", // указываем часовой пояс
      timeZoneName: "short", // добавляем 'UTC'
    };

    const date = new Date(isoDate).toLocaleDateString("en-GB", options);

    const formattedDate = date.toLocaleString("en-US", options);
    return formattedDate;
  };

  const dollars = (subInfo?.amount / 100).toFixed(2);

  const isCanceled = subInfo?.next_billed_at === null;
  return (
    <div className="p-4 flex flex-col w-full">
      <div className="text-xl font-bold">Billing page</div>
      <div className="flex justify-between w-full ">
        <div className="mt-4 flex gap-4 items-start flex-col">
          <div className={"flex flex-col gap-2"}>
            {subInfo?.status === "trialing" &&
              me?.subscription === true &&
              subInfo?.next_billed_at !== null && (
                <div className={"gap-2 flex flex-col"}>
                  <div>
                    Your free trial will end on {` `}
                    <span className="font-bold">
                      {formatDate(subInfo?.next_billed_at)}.
                    </span>
                  </div>
                  <div>
                    Your subscription will renew at {` `}{" "}
                    <span className={"font-bold"}>${dollars}</span> on {` `}
                    <span className="font-bold">
                      {formatDate(me?.due_date)}.
                    </span>
                  </div>
                </div>
              )}

            {subInfo?.status === "trialing" &&
              me?.subscription === true &&
              isCanceled && (
                <div className={"gap-2 flex flex-col"}>
                  <div>
                    Your free trial will end on {` `}
                    <span className="font-bold">
                      {formatDate(me?.due_date)}.
                    </span>
                  </div>
                  <div>Your subscription is canceled.</div>
                </div>
              )}

            {subInfo?.status === "trialing" && me?.subscription === false && (
              <div className={"gap-2 flex flex-col"}>
                <div>
                  Your free trial will end on {` `}
                  <span className="font-bold">
                    {formatDate(subInfo?.next_billed_at)}.
                  </span>
                </div>
                <div>Your subscription is canceled.</div>
              </div>
            )}
            {subInfo?.status === "active" && (
              <div className={"gap-2 flex "}>
                Your subscription will renew at {` `}
                <span className={"font-bold"}>${dollars}</span> on {` `}
                <span className="font-bold">{formatDate(me?.due_date)}.</span>
              </div>
            )}

            {/*{isCanceled && me?.subscription === true && (*/}
            {/*  <div className={"flex gap-2"}>*/}
            {/*    Your subscription will end on*/}
            {/*    <span className="font-bold">{formatDate(me?.due_date)}</span>.*/}
            {/*  </div>*/}
            {/*)}*/}

            {isCanceled &&
              me?.subscription === false &&
              subInfo?.status === "trialing" && (
                <div>Your subscription is canceled.</div>
              )}

            {/*{isCanceled && me?.subscription === false && subInfo?.status !== "trialing" &&  (*/}
            {/*    <div>Your subscription is canceled.</div>*/}
            {/*)}*/}
          </div>

          <Button
            disabled={me?.subscription === false}
            size="sm"
            className="w-[190px]"
            variant="outline"
            onClick={showPaymentModal}
          >
            Change payment method
          </Button>

          <ConfirmationPopup
            disabled={
              me?.subscription === false ||
              isCanceled ||
              subInfo?.status === "canceled"
            }
            text={"Are you sure you want to cancel your subscription?"}
            confirmCb={unsubscribe}
          >
            <Button
              className="w-[190px]"
              disabled={
                me?.subscription === false ||
                isCanceled ||
                subInfo?.status === "canceled"
              }
              size={"sm"}
              variant="exit"
            >
              Cancel subscription
            </Button>
          </ConfirmationPopup>
        </div>
      </div>
    </div>
  );
};
