import AxiosInstance from "@/services/axios-instance.ts";
import { useQuery } from "@tanstack/react-query";
import { toast } from "sonner";
import { useMe } from "@/hooks/useMe.tsx";

type SubscriptionStatus = "active" | "trialing" | "canceled";
export const useSubscriptionInfo = () => {
  const { me } = useMe({});

  const { data: subInfo } = useQuery({
    enabled: me?.subscription === true,
    queryKey: ["subscription-info"],
    queryFn: async ({ signal }) => {
      try {
        const url = `/get_subscription_info`;
        const resp = await AxiosInstance.get<{
          canceled_at: string | null;
          next_billed_at: string | null;
          status: SubscriptionStatus;
          amount: number;
          name: "Monthly Subscription" | "Yearly Subscription";
        }>(url, {
          signal,
        });

        return resp.data;
      } catch (err: any) {
        if (err?.code === "ERR_CANCELED") return null;
        toast.error(err?.message);
        return null;
      }
    },
  });

  return {
    subInfo,
  };
};
