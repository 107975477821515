import { create } from "zustand";
import { persist, createJSONStorage } from "zustand/middleware";

interface MenuStore {
  menuShown: boolean;
  setMenuShown: (menuShown: boolean) => void;
}

const useMenuStore = create<MenuStore>()(
  persist(
    (set) => ({
      menuShown: true,
      setMenuShown: (menuShown) => set({ menuShown }),
    }),
    {
      name: "menu-store",
      storage: createJSONStorage(() => sessionStorage),
    },
  ),
);

export default useMenuStore;
