import { useEffect } from "react";

export const useApplyLastPagination = ({
  type,
  moveToPaginationPage,
}: {
  type: "trends" | "black" | "watch";
  moveToPaginationPage: (number) => void;
}) => {
  const applyLastPagination = () => {
    const savedPage = localStorage.getItem(`last-page-${type}`);
    if (savedPage !== null) {
      moveToPaginationPage(JSON.parse(savedPage));
    } else {
      moveToPaginationPage(1);
    }
  };

  useEffect(() => {
    applyLastPagination();
  }, []);
};
