import { useNavigate } from "react-router-dom";
import { Button } from "@/components/ui/button.tsx";
import { queryClient } from "@/App.tsx";
import useMenuStore from "@/store/useMenuStore.tsx";
import { useEffect } from "react";

export const ErrorBoundaryPage = () => {
  const navigation = useNavigate();

  const { setMenuShown } = useMenuStore();

  const tryHandler = async () => {
    queryClient.clear();
    navigation("/trends");
  };

  useEffect(() => {
    setMenuShown(true);
  }, []);

  return (
    <div className="flex w-full min-h-screen items-center justify-center bg-gray-100">
      <div className="bg-white p-8 rounded-lg-native shadow-lg max-w-md text-center">
        <h1 className="text-2xl font-medium text-red mb-4">We are sorry!</h1>
        <p className="text-gray-700 mb-6">
          We are experiencing a technical problem and cannot process your
          request right now.
        </p>
        <p className="text-gray-700 mb-4">
          Please try again in a couple of minutes or contact us at
          <a
            href="mailto:hello@nextbigniche.ai"
            className="ml-1 text-blue-600 underline"
          >
            hello@nextbigniche.ai
          </a>
        </p>
        <Button variant={"outline"} onClick={tryHandler}>
          Try Again
        </Button>
      </div>
    </div>
  );
};
