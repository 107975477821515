import { PropsWithChildren, useCallback } from "react";
import { useOnboarding } from "./useOnboarding";
import Joyride, { ACTIONS, EVENTS, STATUS, CallBackProps } from "react-joyride";
import "./Onboarding.css";
export const OnboardingWrapper = ({ children }: PropsWithChildren) => {
  const {
    steps,
    isReady,
    currentOnboardingStep,
    setIsReady,
    nextOnboardingStep,
    onboardingCanShow,
    stopOnboarding,
    prevOnboardingStep,
  } = useOnboarding();

  const handleJoyrideCallback = useCallback((data: CallBackProps) => {
    const { action, index, origin, status, type, step, lifecycle } = data;
    console.log("action", action);
    if (action === ACTIONS.PREV && lifecycle === "complete") {
      prevOnboardingStep();
      return;
    }
    if (action === ACTIONS.NEXT && lifecycle === "complete") {
      nextOnboardingStep();
      return;
    }
    if (
      action === ACTIONS.CLOSE
      // && origin === ORIGIN.KEYBOARD
    ) {
      stopOnboarding();
      // console.log("close");
      // startOnboarding(false);
      return;
    }

    // eslint-disable-next-line @typescript-eslint/ban-ts-comment
    // @ts-ignore
    if ([EVENTS.STEP_AFTER, EVENTS.TARGET_NOT_FOUND].includes(type)) {
      console.log("TARGET_NOT_FOUND");
      setIsReady(false);
      // Update state to advance the tour
      // setStepIndex(index + (action === ACTIONS.PREV ? -1 : 1));
      // eslint-disable-next-line @typescript-eslint/ban-ts-comment
      // @ts-ignore
    } else if ([STATUS.FINISHED, STATUS.SKIPPED].includes(status)) {
      setIsReady(false);
    }
  }, []);

  const currentStepClickable = steps[currentOnboardingStep]?.clickable ?? true;

  return (
    <>
      <Joyride
        disableCloseOnEsc
        showSkipButton={false}
        hideCloseButton={true}
        disableScrolling
        disableOverlayClose
        continuous
        steps={steps}
        run={isReady && onboardingCanShow}
        spotlightClicks={currentStepClickable}
        stepIndex={currentOnboardingStep}
        callback={handleJoyrideCallback}
        styles={{
          options: {
            backgroundColor: "#f9f9f9",
            // overlayColor: "#5882bd",
            primaryColor: "#d9376e",
            width: 300,
            zIndex: 1000,
          },
        }}
      />

      {children}
    </>
  );
};
