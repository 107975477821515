import { useForm } from "react-hook-form";
import { useCallback, useMemo } from "react";
import axiosInstance from "@/services/axios-instance.ts";
import { toast } from "sonner";
import { useNavigate, useSearchParams } from "react-router-dom";

interface ResetPasswordFormData {
  emailForRecovery: string;
}

interface NewPasswordFormData {
  password: string;
  confirmPassword: string;
  token: string;
}

const useResetPasswordForm = () => {
  const navigation = useNavigate();
  const [searchParams] = useSearchParams();
  const token = searchParams.get("token");

  const resetForm = useForm<ResetPasswordFormData>({
    mode: "onSubmit",
    shouldFocusError: false,
    defaultValues: {
      emailForRecovery: "",
    },
  });

  const newPasswordForm = useForm<NewPasswordFormData>({
    mode: "onSubmit",
    shouldFocusError: false,
    defaultValues: {
      password: "",
      confirmPassword: "",
      token: "",
    },
  });

  const onSendEmail = useCallback(
    async (data: ResetPasswordFormData) => {
      const url = `/reset-password`;

      try {
        await axiosInstance.post(url, { email: data.emailForRecovery });
        resetForm.reset();
        toast.success("Reset link sent successfully.");
        return true;
      } catch (error) {
        console.error("Failed to send reset password email:", error);
        if (error.response && error.response.status === 404) {
          toast.error("User with this email doesn't exist");
        }
        return false;
      }
    },
    [resetForm],
  );

  const onSendNewPassword = useCallback(
    async (data: NewPasswordFormData) => {
      const url = `/reset-password/${token}`;

      try {
        if (data.password !== data.confirmPassword) {
          toast.error("Passwords don't match.");
          return false;
        }

        await axiosInstance.post(url, {
          password: data.password,
          token: data.token,
        });

        newPasswordForm.reset();
        toast.success("Your password has been updated successfully.");
        navigation("/");
        return true;
      } catch (error) {
        if (error.response) {
          switch (error.response.status) {
            case 400:
              toast.error("Expired link.");
              break;
            case 401:
              toast.error("Invalid format.");
              break;
            default:
              toast.error("Failed to reset password. Please try again.");
          }
        }
        return false;
      }
    },
    [navigation, newPasswordForm],
  );

  return useMemo(
    () => ({
      resetForm,
      newPasswordForm,
      onSubmit: resetForm.handleSubmit(onSendEmail),
      onNewPasswordSubmit: newPasswordForm.handleSubmit(onSendNewPassword),
    }),
    [resetForm, newPasswordForm, onSendEmail, onSendNewPassword],
  );
};

export default useResetPasswordForm;
