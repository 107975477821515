import axios, {
  AxiosRequestConfig,
  AxiosResponse,
  AxiosError,
  AxiosHeaders,
} from "axios";
import { API_URl } from "@/consts/consts";
import useAuthStore from "@/store/useAuthStore.tsx";
import { toast } from "sonner";

export const TOKEN_TYPE = "Bearer ";
export const REQUEST_HEADER_AUTH_KEY = "Authorization";

const AxiosInstance = axios.create({
  timeout: 60000,
  baseURL: API_URl,
});

AxiosInstance.interceptors.request.use(
  (config: AxiosRequestConfig): AxiosRequestConfig => {
    const accessToken = useAuthStore.getState().accessToken;
    if (accessToken) {
      config.headers[REQUEST_HEADER_AUTH_KEY] = accessToken;
    }

    return {
      ...config,
      headers: new AxiosHeaders({
        ...config.headers,
      }),
    };
  },
  (error: AxiosError) => {
    return Promise.reject(error);
  },
);

AxiosInstance.interceptors.response.use(
  (response: AxiosResponse) => response,
  async (error: AxiosError) => {
    const responseStatus = error.response?.status;

    if (responseStatus && responseStatus >= 500 && responseStatus < 600) {
      toast.error("Please wait a few minutes before you try again", {
        duration: 8000000,
        cancel: {
          label: "Hide",
        },
      });

      return Promise.reject(error);
    }

    if (
      (responseStatus === 401 || responseStatus === 403) &&
      !error.config._retry
    ) {
      error.config._retry = true;

      try {
        const refreshToken = useAuthStore.getState().refreshToken;
        if (refreshToken) {
          const response = await axios.post(
            "/token/refresh",
            {
              refresh_token: refreshToken,
            },
            { baseURL: API_URl },
          );

          const tokens = response.data;
          useAuthStore.getState().setToken({
            accessToken: tokens.access_token,
            refreshToken: tokens.refresh_token,
          });

          error.config.headers[REQUEST_HEADER_AUTH_KEY] =
            `${tokens.access_token}`;

          return axios(error.config);
        } else {
          useAuthStore.getState().clearToken();
        }
      } catch (refreshError) {
        useAuthStore.getState().clearToken();
        toast.error("Session expired. Please log in again.", {
          duration: 5000,
          cancel: {
            label: "Hide",
          },
        });
        return Promise.reject(refreshError);
      }
    }

    return Promise.reject(error);
  },
);

export default AxiosInstance;
