import React, { useCallback, useEffect, useMemo, useState } from "react";
import {
  ClientFilters,
  usePaginatedTrends,
} from "@/hooks/usePaginatedTrends/usePaginatedTrends.tsx";

import {
  Select,
  SelectContent,
  SelectItem,
  SelectTrigger,
  SelectValue,
} from "@/components/ui/select.tsx";

import { Button } from "@/components/ui/button.tsx";
import { FilterContainer } from "@/components/filters/FilterContainer/FilterContainer.tsx";

import { ColumnsSettings } from "@/components/ColumnsSettings/ColumnsSettings.tsx";
import { TableFilterDialog } from "@/components/dialogs/TableFiltersDialog/TableFiltersDialog.tsx";

import { TablePagination } from "@/components/table/TablePagination.tsx";
import {
  Cross1Icon,
  HeartIcon,
  PlayIcon,
  TrashIcon,
} from "@radix-ui/react-icons";

import { useMe } from "@/hooks/useMe.tsx";
import { useUserSettings } from "@/hooks/useUserSettings.tsx";
import useMenuStore from "@/store/useMenuStore.tsx";
import SideMenuButton from "@/components/SideMenuButton.tsx";
import { useQueryClient } from "@tanstack/react-query";
import {
  checkStepPositionByName,
  ONBOARDING_CLASSES,
} from "@/components/onboarding/useOnboarding.tsx";
import { useOnboardingStore } from "@/components/onboarding/useOboardingStore.tsx";
import { ConfirmationPopup } from "@/components/ConfirmationPopup/ConfirmationPopup.tsx";
import { IS_STAGING } from "@/consts/consts";
import TrendsTable from "@/components/TrendsTable/TrendsTable.tsx";
import { useTrendsPageScroll } from "@/components/TrendsTable/useTrendsPageScroll.tsx";
import { OnboardingWrapper } from "@/components/onboarding/OnboardingWrapper.tsx";

export type OptionFilterReturn = {
  queryKey: ClientFilters;
  filterValue: string | number | boolean | null | object;
};

export type SortColumnOption = {
  columnKey: ClientFilters;
  sortValue: 1 | -1 | 0;
};

export const TrendsPage: React.FC = () => {
  const { me } = useMe({});

  const {
    paginatedData = [],
    tableHeadRow,
    addFilterHandler,
    addSortHandler,
    hideColumn,
    hiddenRowsIds,
    fetchTable,
    totalPagesCount,
    totalRows,
    currentPaginationPage,
    moveToPaginationPage,
    isPlaceholderData,
    listType,
    appliedSort,
    isFetching,
  } = usePaginatedTrends({ defaultListType: "trends" });

  const { setMenuShown, menuShown } = useMenuStore();
  const { deleteSavedSearchById } = useUserSettings();
  const queryClient = useQueryClient();

  const def = {
    id: "default",
    name: "Default search",
    applied_filters: [],
  };

  const {
    nextStep,
    startOnboarding,
    setCurrentOnboardingStep,
    currentOnboardingStep,
    onboardingCanShow,
  } = useOnboardingStore();

  const savedSearches = useMemo(
    () => (me?.saved_searches ? [def, ...me?.saved_searches] : [def]),
    [def, me?.saved_searches],
  );
  const { scrollAreaRef, scrollToTop } = useTrendsPageScroll();

  const moveToPaginationPageHandler = (pageNum) => {
    localStorage.setItem(`last-page-${listType}`, pageNum);
    moveToPaginationPage(pageNum);
    setTimeout(() => scrollToTop(), 100);
  };

  const { addTrendToBlockList, addTrendToWatchList, addFiltersList } =
    useUserSettings();

  const addFilters = async ({ filters }: { filters: OptionFilterReturn[] }) => {
    await addFiltersList({ filterList: filters });
    addFilterHandler({ newFilters: filters });
  };

  const [savedSearchFilters, setSavedSearchFilters] = useState([]);

  const applyLastPagination = () => {
    const savedPage = localStorage.getItem(`last-page-trends`);
    if (savedPage !== null) {
      moveToPaginationPage(JSON.parse(savedPage));
    } else {
      moveToPaginationPage(1);
    }
  };

  useEffect(() => {
    applyLastPagination();
  }, []);

  const removeSelectedRowId = (id: number) => {
    setSelectedRows((prevRows) => prevRows.filter((rowId) => rowId !== id));
  };

  const [selectedRows, setSelectedRows] = useState<number[]>([]);

  const addSelectedRowId = (id: number) => {
    if (selectedRows.some((r) => r === id)) {
      return;
    }

    setSelectedRows((prevRows) => [...prevRows, id]);
  };

  const addWatchList = useCallback(async () => {
    await addTrendToWatchList({ trends_ids: selectedRows });
    setSelectedRows([]);
    void fetchTable();
  }, [addTrendToWatchList, fetchTable, selectedRows]);

  useEffect(() => {
    const showMenu =
      [
        checkStepPositionByName(ONBOARDING_CLASSES.blackListMenuHighlight),
      ].includes(currentOnboardingStep) && onboardingCanShow;

    if (showMenu) setMenuShown(true);
  }, [currentOnboardingStep, onboardingCanShow, setMenuShown]);

  useEffect(() => {
    const canAdd =
      [checkStepPositionByName(ONBOARDING_CLASSES.blackListAddButton)].includes(
        currentOnboardingStep,
      ) && onboardingCanShow;

    if (canAdd && selectedRows.length !== 0) {
      // void addWatchList();
      setSelectedRows([]);
    }
  }, [addWatchList, currentOnboardingStep, onboardingCanShow, selectedRows]);

  return (
    <OnboardingWrapper>
      <div
        className={`flex flex-col max-h-screen w-full ${ONBOARDING_CLASSES.welcome}`}
      >
        <div className="flex w-full flex-wrap justify-between my-[12px] gap-4 px-[12px]">
          <div className={"flex gap-4"}>
            <FilterContainer>
              <SideMenuButton
                menuShown={menuShown}
                setMenuShown={setMenuShown}
              />
            </FilterContainer>

            <TableFilterDialog
              isLoading={isFetching}
              disabled={isFetching}
              savedSearchFilters={savedSearchFilters}
              addFilters={addFilters}
              onSearch={async () => {
                await fetchTable();
                moveToPaginationPageHandler(1);
              }}
            />

            <FilterContainer
              className={`${ONBOARDING_CLASSES.savedSearchesHighlight}`}
            >
              <Select
                onValueChange={async (f) => {
                  const newFilters = [...f];
                  await addFilters({ filters: newFilters });
                  await queryClient.invalidateQueries({ queryKey: ["table"] });

                  setSavedSearchFilters(newFilters);
                  setTimeout(() => setSavedSearchFilters([]), 500);
                }}
              >
                <SelectTrigger className={`w-fit `}>
                  <SelectValue
                    className={`bg-purple-300 text-sm `}
                    placeholder={"Saved searches"}
                  />
                </SelectTrigger>
                <SelectContent>
                  {savedSearches.map((option) => (
                    <div className={"flex items-center"}>
                      <SelectItem
                        className={"cursor-pointer hover:bg-orange/10"}
                        key={option.id}
                        value={option.applied_filters}
                      >
                        {option.name}
                      </SelectItem>
                      {option.id !== "default" && (
                        <div className={"p-[5px]"}>
                          <TrashIcon
                            onClick={() =>
                              deleteSavedSearchById({ id: option.id })
                            }
                            className={"cursor-pointer hover:text-orange"}
                          />
                        </div>
                      )}
                    </div>
                  ))}
                </SelectContent>
              </Select>
            </FilterContainer>

            <FilterContainer>
              <ColumnsSettings
                hiddenRowsIds={hiddenRowsIds}
                hideColumn={hideColumn}
              />
            </FilterContainer>
            <div className={" flex w-fit  gap-[16px] "}>
              <div className={"flex w-fit gap-[16px]"}>
                <FilterContainer>
                  <Button
                    className={`${ONBOARDING_CLASSES.watchListAdddButton}`}
                    disabled={selectedRows.length === 0}
                    size={"sm"}
                    variant="outline"
                    onClick={() => {
                      if (onboardingCanShow) {
                        nextStep();
                        setSelectedRows([]);
                      } else {
                        void addWatchList();
                      }
                    }}
                  >
                    <HeartIcon className={"mr-[6px]"} />
                    Add to watchlist
                  </Button>
                </FilterContainer>
                <FilterContainer>
                  <Button
                    disabled={selectedRows.length === 0}
                    size={"sm"}
                    variant="outline"
                    className={`${ONBOARDING_CLASSES.blackListAddButton}`}
                    onClick={async () => {
                      await addTrendToBlockList({ trends_ids: selectedRows });
                      setSelectedRows([]);
                      void fetchTable();
                    }}
                  >
                    <TrashIcon className={"mr-[6px]"} />
                    Add to blacklist
                  </Button>
                </FilterContainer>
                {selectedRows.length !== 0 && (
                  <FilterContainer>
                    <Button
                      disabled={selectedRows.length === 0}
                      size={"sm"}
                      variant="outline"
                      onClick={() => {
                        setSelectedRows([]);
                      }}
                    >
                      <Cross1Icon className={"mr-[6px]"} />
                      Cancel
                    </Button>
                  </FilterContainer>
                )}
              </div>
            </div>
          </div>

          {paginatedData.length > 5 && (
            <FilterContainer>
              <ConfirmationPopup
                text={"Start training?"}
                confirmCb={() => {
                  setCurrentOnboardingStep(0);
                  setMenuShown(false);
                  scrollToTop();
                  startOnboarding();
                }}
              >
                <Button size={"sm"} variant="outline">
                  <PlayIcon className={"mr-[6px]"} />
                  Training
                </Button>
              </ConfirmationPopup>
            </FilterContainer>
          )}
        </div>
        <TrendsTable
          ref={scrollAreaRef}
          addSort={({ sort }: { sort: SortColumnOption[] }) => {
            addSortHandler({ newSort: sort });
          }}
          tableHeadRow={tableHeadRow}
          hiddenRowsIds={hiddenRowsIds}
          appliedSort={appliedSort}
          isFetching={isFetching}
          isPlaceholderData={isPlaceholderData}
          paginatedData={paginatedData}
          addSelectedRowId={addSelectedRowId}
          removeSelectedRowId={removeSelectedRowId}
          selectedRows={selectedRows}
        />
        <div className="flex py-6 w-screen bg-gray-50 items-center text-black">
          <div className="absolute right-4">
            <span className="text-md font-medium">
              {paginatedData.length} of{" "}
              {typeof totalRows === "number"
                ? totalRows.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",")
                : totalRows}{" "}
            </span>
            <span className="text-sm font-regular">total niches</span>
          </div>
          <div className="absolute flex left-[44%]">
            <TablePagination
              totalPagesCount={totalPagesCount}
              currentPaginationPage={currentPaginationPage}
              moveToPaginationPage={moveToPaginationPageHandler}
            />
          </div>
        </div>
      </div>
    </OnboardingWrapper>
  );
};
