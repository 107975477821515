import { useCallback, useMemo } from "react";
import { useForm } from "react-hook-form";
import useAuthStore from "@/store/useAuthStore.tsx";
import { toast } from "sonner";
import { useNavigate } from "react-router-dom";
import AxiosInstance from "@/services/axios-instance.ts";

export const useLoginForm = () => {
  const { setToken } = useAuthStore();

  const navigation = useNavigate();

  const form = useForm<{ email: string; password: string }>({
    mode: "onSubmit",
    shouldFocusError: false,
    defaultValues: {
      email: "",
      password: "",
    },
  });

  const onLogin = useCallback(async () => {
    const { email, password } = form.getValues();
    const url = `/login`;
    try {
      const resp = await AxiosInstance.post<{
        access_token: string;
        refresh_token: string;
      }>(url, {
        email: email.trim(),
        password: password.trim(),
      });

      setToken({
        accessToken: resp?.data?.access_token,
        refreshToken: resp?.data?.refresh_token,
      });
      navigation("/trends");
    } catch (error) {
      console.error("signup error", error.response?.data?.message);
      toast.error("Something went wrong");
    }

    form.reset({ email, password: "" });
  }, [form, navigation, setToken]);

  return useMemo(
    () => ({
      form,
      onSubmit: form.handleSubmit(onLogin),
    }),
    [form, onLogin],
  );
};
